var gnavMyAccountCardCallback = gnavMyAccountCardCallback || {};

gnavMyAccountCardCallback.myAccountProfileLinks = gnavMyAccountCardCallback.myAccountProfileLinks || {};

gnavMyAccountCardCallback.myAccountProfileLinks = function (html, data, user) {
  var cl = Drupal.cl;
  var userData = user.userData();
  var selectors = [];

  if (userData.pc_email_optin == 1) {
    selectors.push('.stay-connected');
  }

  if (userData.mobile_email_optin == 1) {
    selectors.push('.sms');
  }

  if (userData.default_addresses == 1) {
    selectors.push('.shipping');
  }

  if (userData.skin_data == 1) {
    selectors.push('.skin-data');
  }

  if (selectors.length > 0) {
    html.find(cl.implode(', ', selectors)).addClass('gray');
  }
};
